import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';

import { EnvironmentService } from '@services/environment/environment.service';

import { BaseComponent } from '@core/components/base/base-component';
import { IconComponent } from '../icon/icon.component';

import { UrlFixedPipe } from 'src/app/pipes';

import { MimeTypesEnum } from '@models/enums';

@Component({
  standalone: true,
  selector: 'sps-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, UrlFixedPipe, IconComponent, NgTemplateOutlet],
})
export class LinkComponent extends BaseComponent {
  @Input() href: string = null;
  @Input() icon: boolean | string = true;
  @Input() target: '_self' | '_blank' = null;

  private fileExtensions = Object.values(MimeTypesEnum).map(ext => ext.toLowerCase());
  private readonly defaultIcon = 'navigate_next';

  get isFile(): boolean {
    const href = (this.href ?? '').toLowerCase();
    return this.fileExtensions.some(ext => href.endsWith(`.${ext}`));
  }

  get internal(): boolean {
    // Consider links to files as external
    if (this.isFile) {
      return false;
    }

    const href = this.href ?? '';
    return href.startsWith(EnvironmentService.cmsHost) || href.startsWith('/');
  }

  get external(): boolean {

    return this.target === '_blank' || !this.internal;
  }

  get sectionlink(): boolean{
    // hot fix for section link inside same page
    if(this.href.indexOf("spsglobal.com")!=-1 && this.href.indexOf("#")!=-1){
      return true;
    }
    return false;
  }

  get linkTarget(): string {
    if (this.target) {
      return this.target;
    }

    return this.isFile ? '_self' : '_blank';
  }

  get iconName(): string | null {
    if (!this.icon) {
      return null;
    }

    return typeof this.icon === 'boolean' ? this.defaultIcon : this.icon;
  }
}
